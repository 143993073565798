import React from "react"
import Header from "../components/header"
import { StaticImage } from "gatsby-plugin-image"

const FormSuccess = () => {
  return (
    <div
      css={{
        width: "100%",
        marginBottom: 50,
        "@media(min-width: 768px)": {
          marginBottom: 0,
        },
      }}
    >
      <Header />
      <div
        css={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          backgroundColor: "#fbf8f3",
          flexDirection: "column",
        }}
      >
        <StaticImage
          src={"../images/lotusclinic-logo.svg"}
          width={250}
          alt={"菡生美無極診所"}
        />
        <p css={{ textAlign: "center", marginTop: "2rem" }}>
          感謝您的留言，我們將儘速回覆您的問題!
          <br />
          我們將為您帶來性感美麗、健康自信的專屬療程
          <br />
          呵護自己，活得精彩，享受幸福!
          <br />
          <a
            href={"/"}
            css={{
              display: "inline-block",
              border: "1px solid #000",
              padding: "1rem 2rem",
              color: "#000",
              marginTop: "0.5rem",
              "&:hover": {
                color: "#000",
              },
            }}
          >
            回到首頁
          </a>
        </p>
      </div>
    </div>
  )
}

export default FormSuccess
